.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .header {
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    min-height: 16px;
    .requiredFlag {
      color: red;
      margin-left: 3px;
      font-size: 16px;
      letter-spacing: .2px;
      line-height: 16px;
    }
  }
  .value {
    input, textarea {
      width: 100%;
      margin-top: 4px;
      border: 1px solid #eee;
      background: #fafafa;
      border-radius: 4px;
      padding: 6px 8px;
      font-size: 13px;
      &::placeholder {
        font-size: 13px;
        line-height: 18px;
      }
      // &:disabled {
      //   font-weight: 600;
      //   font-size: 14px;
      //   border: none;
      //   cursor: text;
      //   background: transparent;
      //   padding: 0;
      // }
    }
    textarea {
      resize: none;
      height: unset;
    }
    p {
      font-size: 14px;
      margin-top: 4px;
      line-height: 21px;
      white-space: pre-line;
    }
    &.requiredAlert {
      input, textarea {
        border: 1px solid var(--red-clr);
        &:focus {
          outline: 1px solid var(--red-clr);
        }
      }
    }
  }
  .error {
    margin-top: 4px;
    font-size: 12px;
    color: #fa553f;
    max-width: 250px;
  }
}

// @media screen and (max-width: 767px) {
//   .inputGroup {
//     display: flex;
//     flex-direction: column;
//     &:not(:last-child) {
//       margin-right: unset;
//     }
  
//     .header {
//       font-size: 12px;
//       color: #666666;
//     }
//     .value {
//       font-weight: 600;
//       font-size: 12px;
//       input, textarea {
//         width: 100%;
//         margin-top: 4px;
//         background: #fafafa;
//         border-radius: 4px;
//         padding: 12.5px;
//         font-size: 12px;
//         border: 1px solid #eee;
//         &:disabled {
//           font-weight: 600;
//           font-size: 16px;
//           cursor: text;
//           background: transparent;
//           color: #000;
//           padding: 0;
//         }
//       }
//       textarea {
//         resize: none;
//         height: unset;
//       }
//     }
//     p {
//       opacity: 0.7;
//       white-space: pre-line;
//     }
//     .error {
//       margin-top: 4px;
//       font-size: 12px;
//       color: #fa553f;
//     }
//   }
// }

// @media screen and (max-width: 360px) {
//   .inputGroup {
//     .header {
//       font-size: 10px;
//     }
//     .value {
//       font-weight: 600;
//       font-size: 12px;
//       input, textarea {
//         padding: 8px;
//         font-size: 14px;
//         border: 1px solid #eee;
//         &:disabled {
//           font-weight: 600;
//           font-size: 12px;
//           cursor: text;
//           background: transparent;
//           padding: 0;
//         }
//       }
//       textarea {
//         resize: none;
//         height: unset;
//       }
//     }
//     p {
//       opacity: 0.7;
//       white-space: pre-line;
//     }
//     .error {
//       margin-top: 4px;
//       font-size: 10px;
//       color: #fa553f;
//     }
//   }
// }
