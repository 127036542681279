.dropdown {
  display: flex;
  position: relative;
  .value {
    height: 32px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    padding: 6px;
    width: fit-content;
    padding-right: 42px;
    border: 1px solid #ccc;
    background-color: #fff;
    .close {
      position: absolute;
      top: 9px;
      right: 22px;
      filter: invert(100%);
      // transform: rotate(90deg);
    }
    .caret {
      position: absolute;
      top: 12px;
      right: 6px;
    }
    input {
      width: 100%;
      border: none;
      background-color: unset;
      outline: none;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .menuWrapper {
    position: absolute;
    top: 38px;
    border-radius: 3px;
    z-index: 998;
    &.limitHeightItems {
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: var(--green-clr);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }  
    }
    background-color: #fff;
    border: 1px solid #ccc;
    &.toogleRight {
      right: 0;
    }
    .items {
      width: 100%;
      .title {
        padding: 6px 8px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        border-bottom: 1px solid #eee;
      }  
      .item {
        padding: 8px 4px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        &:hover {
          opacity: 0.7;
        }
        .check {
          width: 12px;
          margin-right: 8px;
          flex-shrink: 0;
        }
        .label {
          font-size: 11px;
          line-height: 18px;
          color: #666;
        }
      }
    }
  }
}
