.help {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 1px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 8px;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background-color: #cccccc;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 1;
  .tooltip {
    background-color: #fff;
    color: #000;
    font-size: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 6px;
    position: absolute;
    white-space: pre-line;
    bottom: calc(100% + 10px);
    a {
      color: var(--green-clr);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
