.coursesListWrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  .sectionTitle {
    font-size: 16px;
    line-height: 24ppx;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .coursesList {
    display: flex;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
       display: none;
    }
  }
  .nextBtn, .backBtn {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fff;
    z-index: 1000;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 45%;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  }
  .backBtn {
    left: 0px;
    img {
      transform: rotate(-180deg);
    }      
  }
  .nextBtn {
    right: 0px;
  }
}

@media screen and (max-width: 767px) {
  .coursesListWrapper .coursesList {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
