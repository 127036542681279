.timeInputWrapper {
  position: relative;
  display: inline;
  &.withTime {
    width: 340px;
    .datePicker {
      width: 340px;
    }
  }
  .close {
    position: absolute;
    top: 2px;
    right: 8px;
    z-index: 1;
    filter: invert(100%);
    // transform: rotate(90deg);
  }
  .arrow {
    border : none;
    background : none;
    position: absolute;
    top: -1px;
    right: 10px;
  }
  .timeInput {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    padding: 0.7rem 2.25rem 0.7rem 0.75rem;
    cursor: pointer;
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
  .tableTimeInput {
    background: #FFFEF1;
    border: none;
    // width: 100%;
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
  .datePicker {
    position: absolute;
    top: 35px;
    z-index: 1000;
    left: 0;
    :global(.react-datepicker__header) {
      border-bottom: none;
      background-color: #fff;
    }
    :global(.react-datepicker__navigation-icon::before) {
      border-color: #000;
      border-width: 2px 2px 0 0;
    }
    :global(.react-datepicker__current-month) {
      font-size: 14px;
    }
    :global(.react-datepicker__navigation--previous), :global(.react-datepicker__navigation--next) {
      top: 5px;
    }
    :global(.react-datepicker__month-wrapper) {
      display: flex;
      :global(.react-datepicker__month-text) {
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }
    // :global(.react-datepicker__month-container) {
      // width: 180px;
    // }
    :global(.react-datepicker__time-container) {
      :global(.react-datepicker__time-list-item) {
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    :global(.react-datepicker__day-names) {
      margin-top: 10px;
      :global(.react-datepicker__day-name) {
        color: #828282;
        font-size: 12px;
        line-height: 18px;
        margin: 0.25em;
        width: 2em;
      }
    }
    :global(.react-datepicker__day) {
      font-size: 12px;
      line-height: 18px;
      margin: 0.25em;
      width: 2em;
    }
    :global(.react-datepicker__year-read-view) {
      margin-top: 10px;
      :global(.react-datepicker__year-read-view--down-arrow) {
        border-color: #000;
        border-width: 2px 2px 0 0;
        height: 8px;
        width: 8px;
        top: 3px;
      }
      :global(.react-datepicker__year-read-view--selected-year) {
        font-size: 12px;
        font-weight: 600;
      }
    }
    :global(.react-datepicker__navigation--years-upcoming) {
      text-indent: unset;
      display: flex;
      align-items: center;
      &::after {
        content: '🔺';
        font-size: 16px;
      }
    }
    :global(.react-datepicker__navigation--years-previous) {
      text-indent: unset;
      display: flex;
      align-items: center;
      &::after {
        content: '🔻';
        font-size: 16px;
      }
    }
    :global(.react-datepicker__year-option) {
      font-size: 12px;
      line-height: 30px;
    }
    :global(.react-datepicker-year-header) {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) {
  .timeInputWrapper .datePicker {
    :global(.react-datepicker__navigation) {
      :global(.react-datepicker__navigation-icon--previous), :global(.react-datepicker__navigation-icon--next) {
        top: 5px;
      }
    }
  }
}
