.timelineSection {
  padding: 20px 24px;
}

.tabList {
  position: sticky;
  top: 64px;
  margin-top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  background-color: var(--light-gray-1-clr);
  border-top: 1px solid var(--light-gray-clr);
  border-bottom: 1px solid var(--light-gray-clr);
  .tab {
    outline: 0;
    flex-grow: 1;
    .tabContent {
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      width: 100%;
      font-size: 16px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      color: var(--dark-gray-clr);
      cursor: pointer;
      &:hover {
        color: var(--black-primary-1-clr);
        background-color: #e1f1e7;
        font-weight: 600;
      }
    }
    &.selected {
      background-color: #D0FBE1;
      .tabContent {
        color: var(--black-primary-1-clr);
        border-bottom: 4px solid var(--green-clr);
        font-weight: 600;
      }
    }
  }
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  .emptyText {
    margin-top: 35px;
    font-weight: 600;
    font-size: 16px;
    color: #666666;
  }
}

.profilePageWrapper {
  .addPostBtn {
    width: 96px;
    height: 96px;
    position: fixed;
    border-radius: 50%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    background: #0abc71;
    z-index: 1000;
  }
}

@media screen and (max-width: 1200px) {
  .tabList {
    top: 64px;
  }
}

@media screen and (max-width: 767px) {
  .timelineSection {
    padding: 10px 8px 110px 8px;
  }
  .tabList {
    top: 64px;
    margin-bottom: 10px;
    .tab {
      flex-grow: 1;
      .tabContent {
        font-size: 14px;
        padding: 10px 5px;
      }
    }
  }
}
