.customSwitch {
  display: flex;
  align-items: center;
  .switch {
    display: flex;
    align-items: center;
    .flag {
      color: #000;
      font-size: 14px;
      line-height: 21px;
      margin-right: 8px;
    }
  }
  .root {
    width: 42px;
    height: 26px;
    padding: 0;
    margin: 1px;
  }
  .switchBase {
    padding: 1px;
    &.checked {
      transform: translateX(16px);
      color: #ffffff;
      & + .track {
        background-color: #52d869;
        opacity: 1;
        border: none;
      }
    }
    &.focusvisible .thumb {
      color: #52d869;
      border: 6px solid #ffffff;
    }
  }
  .thumb {
    width: 24px;
    height: 24px;
  }
  .track {
    border-radius: 13px;
    border: 1px solid #cccccc;
    background-color: #eeeeee;
    opacity: 1;
    transition: background-color border 1s ease-in-out;
  }
}
