.courseItemView {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #ccc;
  .thumbnailWrapper {
    display: flex;
    overflow: hidden;
    width: 280px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 1px solid #ccc;
    // background-color: #eee;
    img {
      cursor: pointer;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
  .courseInfo {
    padding: 8px 12px 12px 12px;
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
    .courseTitle {
      font-size: 14px;
      line-height: 21px;
      min-height: 42px;
      color: #000000;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // margin-bottom: 12px;
      overflow: hidden;
      &:hover {
        text-decoration: underline;
      }
    }
    .ratingAndCategory {
      display: flex;
      align-items: center;
    }
    .categoryItem {
      background: rgba(209, 209, 209, 0.66);
      border-radius: 5px;
      display: flex;
      width: fit-content;
      color: #666666;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      margin-right: 10px;
      margin-bottom: 4px;
      padding: 6px 10px;
    }
  }
  &.big {
    width: 100%;
    margin-right: 0px;
    .thumbnailWrapper {
      width: 100%;
    }
    .courseInfo .courseTitle {
      min-height: unset;
    }
  }
  &.medium {
    width: calc(50% - 8px);
    margin-right: 0px;
    &:first-child {
      margin-right: 16px;
    }
    .thumbnailWrapper {
      width: 100%;
    }
  }
  &.small {
    width: 280px;
    margin-right: 15px;
    .thumbnailWrapper img {
      max-width: 278px !important;
    }
  }
}

@media screen and (max-width: 560px) {
  .courseItemView {
    &.medium {
      width: 280px;
      margin-right: 15px;
      .thumbnailWrapper {
        width: 280px;
      }
    }  
  }
}

@media screen and (max-width: 767px) {
  .courseItemView {
    .courseInfo {
      .courseTitle {
        font-size: 12px;
        line-height: 21px;
        min-height: 41px;
      }
    }
  }  
}
