.inputProfileSection {
  width: 100%;
  padding-bottom: 50px;
}
.empty {
  margin-top: 50px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputInfoGroup {
  width: 100%;
  padding: 20px 0 16px 0;
  &:not(:first-child) {
    border-top: 3px solid #ccc;
  }
  .groupHeader {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 5px;
    .groupName {
      font-size: 24px;
      line-height: 27px;
      font-weight: 800;
    }
    .rightSide {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
    .publicHelpIcon {
      background-color: var(--green-clr) !important;
      color: #fff !important;
    }
    .warningText {
      font-size: 13px;
      line-height: 18px;
      font-weight: 600;
      margin-left: 15px;
      color: red;
    }
  }
}

.inputInfoSubGroup {
  width: 100%;
  padding: 15px 16px;
  &.editMode {
    border: 1px dashed #ccc;
  }
  // &:not(:last-child) {
  //   border-bottom: 1px solid #ccc;
  // }
  .subGroupHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .subGroupName {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      flex-shrink: 0;
      &.highlightName {
        color: red;
      }  
    }
    .rightSide {
      margin-left: 5px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      button {
        margin-left: 10px;
        font-size: 11px;
        flex-shrink: 0;
      }
      .errorMessenge {
        color: red;
        font-size: 11px;
        line-height: 18px;
        margin-left: 10px;
      }
    }
  }
}

// edit mode textarea
.inputTextContent {
  resize: none;
  height: unset;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  line-height: 18px;
  max-height: 100px;
  padding: 5px 6px;
  background-color: #eee;
}

// edit mode input
.inputTextContentOneLine {
  width: 200px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 6px;
  background-color: #eee;
  &::placeholder {
    font-size: 11px;
    color: #666666;
    opacity: 0.8;
  }
}

// view mode
.textContent {
  font-size: 13px;
  line-height: 18px;
  color: #666;
  display: block;
  white-space: pre-line;
  max-height: 100px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--green-clr);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.languagesEditMode {
  display: flex;
  flex-direction: column;
  .addLanguage {
    width: 200px;
    margin-top: 20px;
    button {
      font-size: 12px;
    }
  }
  .languageItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    // border-bottom: 1px solid #eee;
    .index {
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      min-width: 20px;
      margin-right: 10px;
      color: #666;
      flex-shrink: 0;
    }
    .languageLevelSelect {
      margin-left: 10px;
      margin-right: 15px;
    }
    .deleteIcon {
      flex-shrink: 0;
    }
  }
}

.languages {
  display: flex;
  flex-direction: column;
  .languageItem {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    // border-bottom: 1px solid #eee;
    .name {
      font-size: 14px;
      color: #666;
      line-height: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .level {
      font-size: 12px;
      color: #666;
      line-height: 18px;
      color: #666;
    }
  }
}

.skills {
  display: flex;
  flex-direction: column;
  .subItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    // &::before {
    //   content: '・';
    //   font-weight: 600;
    //   font-size: 18px;
    //   margin-right: 2px;
    //   color: #666;
    // }
    .subItemName {
      font-size: 13px;
      color: #666;
      line-height: 20px;
      font-weight: 600;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .subItemTime {
      font-size: 13px;
      color: #666;
      font-weight: 600;
      line-height: 20px;
      margin-left: 20px;
      width: 80px;
    }
  }
}

.skillsEditMode {
  display: flex;
  flex-direction: column;
  .addSkill {
    margin-top: 15px;
    button {
      font-size: 11px;
    }
  }
  .addSubSkill {
    margin-top: 15px;
    padding-left: 30px;
    button {
      font-size: 11px;
    }
  }
  .subItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0 3px 15px;
    .subItemName {
      display: flex;
      align-items: center;
      .subItemIndex {
        font-size: 11px;
        color: #666;
        line-height: 18px;
        font-weight: 600;
        margin-right: 10px;
      }
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      opacity: 1;
    }
    input[type=number] {
      text-align: center;
    }
    .subItemYear {
      font-size: 12px;
      color: #666;
      line-height: 18px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .subItemMonth {
      font-size: 12px;
      color: #666;
      line-height: 18px;
      margin-right: 15px;
    }
  }
}

.infoShow {
  color: #666;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  white-space: pre-line;
  word-wrap: break-word;
}

.workExperiences {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  .workExperience {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:not(:first-child) {
      margin-top: 20px;
    }
    .companyName {
      font-size: 15px;
      line-height: 22px;
      color: #666;
      font-weight: 600;
    }
    .timeInfo {
      font-size: 13px;
      line-height: 18px;
      color: #666;
      margin-top: 5px;
    }
  }
}

.workExperiencesEditMode {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  .workExperience {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px dashed #ccc;
    .companyName {
      display: flex;
      align-items: center;
      .exIndex {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        margin-right: 10px;
        min-width: 20px;
        color: #666;
        flex-shrink: 0;
      }
      .changePositionWrapper {
        margin-left: auto;
        display: flex;
        align-items: center;
        .down {
          transform: rotate(180deg);
        }
        .up {
          margin-left: 10px;
        }
      }
    }
    .contractType {
      padding-left: 30px;
    }
    .timeInfo {
      display: flex;
      align-items: center;
      margin-top: 5px;
      padding-left: 30px;
    }
  }
  .deleteIcon {
    width: 24px;
    height: 24px;
    margin-left: 15px;
  }

  .exTitle {
    display: flex;
    align-items: center;
    // .requiredFlag {
    //   color: red;
    //   margin-left: 3px;
    //   font-size: 16px;
    //   letter-spacing: .2px;
    //   line-height: 24px;
    // }
  }
  .addEx {
    margin-top: 10px;
    button {
      font-size: 11px;
    }
  }
  .addWorkEx {
    margin-top: 20px;
    button {
      font-size: 11px;
    }
  }
  .timelineTitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .dateInputGroup {
    display: flex;
    align-items: center;
  }
}

.timeline {
  border-left: 2px solid #ccc;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;    
  background: fade(white, 3%);
  color: fade(white, 80%);
  letter-spacing: 0.5px;
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;   
  padding: 0 30px;
  padding-right: 0;
  list-style: none;
  text-align: left;  
  font-weight: 100;
  max-width: 100%;
    
  .event {
    border-bottom: 1px dashed fade(white, 10%);
    padding-bottom: 10px;
    position: relative;
    .department {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 3px;
      font-weight: 600;
      color: #666;
    }
    .dateRange {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 3px;
      font-weight: 500;
      color: #666;
    }
    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0; 
      border: none;      
    }

    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:after {
      box-shadow: 0 0 0 4px fade(#666, 100%);    
      left: (30px + 3px + (9px * 0.35)) * -1;        
      background: lighten(#666, 5%);    
      border-radius: 50%;  
      height: 9px;
      width: 9px;
      content: "";
      top: 8px;
    }
  }
}

.studyExperiences {
  width: 100%;
  display: flex;
  flex-direction: column;
  .studyExperience {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:not(:first-child) {
      margin-top: 20px;
    }
    .studyTitle {
      color: #666;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 6px;
    }
    .studyName {
      color: #666;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 2px;
    }
    .studyTime {
      color: #666;
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 2px;
    }
    .studyContent {
      padding-left: 15px;
    }
  }
}

.studyExperiencesEditMode {
  width: 100%;
  display: flex;
  flex-direction: column;
  .studyExperience {
    display: flex;
    flex-direction: column;
    border: 1px dashed #ccc;
    padding: 10px;
    width: 100%;
    // .studyTitle {
    //   color: #666;
    //   font-weight: 600;
    //   font-size: 14px;
    //   line-height: 21px;
    //   margin-bottom: 6px;
    // }
    .studyName {
      display: flex;
      align-items: center;
    }
    .changePositionWrapper {
      margin-left: auto;
      display: flex;
      align-items: center;
      .down {
        transform: rotate(180deg);
      }
      .up {
        margin-left: 10px;
      }
    }
    .exIndex {
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      margin-right: 10px;
      min-width: 20px;
      color: #666;
      flex-shrink: 0;
    }
    .deleteIcon {
      margin-left: 10px;
      width: 24px;
      height: 24px;
    }
    .status {
      padding-left: 30px;
    }
    .studyTime {
      display: flex;
      align-items: center;
      padding-left: 30px;
    }
    .studyExDes {
      padding-left: 30px;
    }
  }
  .addSchool {
    margin-top: 20px;
    button {
      font-size: 12px;
    }
  }
}

.dateInput {
  max-width: 120px;
}

.customDropdown {
  :global(.value) {
    min-width: 150px;
    padding-top: 6px;
    padding-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 270px;
    font-size: 13px;
    background-color: #fafafa !important;
    border: 1px solid #eee;
    margin-top: 4px;
    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
    }
  }
  :global(.item) {
    min-width: 165px;
    max-width: 200px;
  }
}

.inputAdressEditMode {
  width: 100%;
  .addressInputGroup {
    display: flex;
    align-items: center;
    width: 100%;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      opacity: 0;
    }
  }
  .devide {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
  }
  .searchBtn {
    margin-left: 20px;
    font-weight: 600;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 15px;
    color: #0abc71;
  }
}

.inputNameEditMode {
  width: 100%;
  .nameInputGroup {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
}

.progressBarSection {
  display: flex;
  flex-direction: column;
  padding: 16px;
  .progressTitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
  }
  .alertText {
    font-size: 12px;
    line-height: 18px;
    color: red;
    margin-top: 4px;
    strong {
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .progressInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .number {
    margin-left: 10px;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 21px;
  }
  .progressBar {
    position: relative;
    display: flex;
    margin-top: 4px;
    // border: 1px solid #ccc;
    width: 500px;
    height: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: var(--green-clr);
    // background: linear-gradient(89.64deg, #EAE221 0.31%, #98D833 66.06%, #5CA927 140.72%);
    // background: linear-gradient(to right, #FF0E0E 0%, #FFEB35 30%, #4CE6A5 60%, #2684F3 100%);
    .notYetOverlay {
      // background: #eee;
      // background: linear-gradient(89.64deg, #CE1638 0.31%, #FF61C0 105.7%);
      background-color: #ccc;
      position: absolute;
      border-top-right-radius: 8.5px;
      border-bottom-right-radius: 8.5px;
      border: none;
      right: 0;
      bottom: 0;
      height: 10px;
      animation: 2s in-out forwards;
      &.fullWidth {
        border-radius: 8.5px;
      }
    }
  }
}

.checkboxWrapper {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  flex-shrink: 0;
  padding-top: 5px;
  cursor: pointer;
  align-items: center;
  input[type="checkbox"] {
    margin-right: 10px;
    cursor: pointer;
  }
  span {
    font-size: 14px;
    line-height: 21px;
  }
}

@keyframes in-out {
  0% {
    width: 0%;
    background: transparent;
    transform: translateX(100%);
  }
  45%, 55% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 767px) {
  .textContent {
    font-size: 12px;
    line-height: 21px;
  }
}
